<template>
    <div class="qrcode-wrapper" id="qrcode"></div>
</template>

<script>
    import QRCode from 'qrcodejs2'
    export default {
        name: "index",
        mounted() {
            this.$nextTick (function () {
                this.qrcode();
            })
        },
        methods: {
            //  生成二维码
            qrcode () {
                const link = 'https://h5.device.jiot.link/#/deviceRepair?type=sao&deviceName=挤压机2号&deviceNo=DEV-0002'
                new QRCode('qrcode', {
                    width: 100,
                    height: 100,        // 高度
                    text: link  // 二维码内容
                })
            }
        }
    }
</script>

<style scoped lang="less">

</style>
